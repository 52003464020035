<template>
  <v-app-bar
    id="app-header"
    class="md:pr-3 md:px-4 bg-black"
    :class="{ 'pl-3': isSettingsHome }"
    height="55"
    flat
  >
    <v-btn
      v-if="!isSettingsHome"
      @click="toggle"
      class="md:hidden! mr-2"
      size="small"
      rounded="lg"
      tile
      icon
    >
      <Icon name="material-symbols:menu" size="25" />
    </v-btn>

    <Icon
      class="mr-2"
      name="clarity:settings-line"
      size="25"
      :class="{ 'lt-md:hidden!': !isSettingsHome }"
    />

    <router-link
      class="decoration-none text-inherit!"
      :to="{ name: 'settings' }"
      >Settings</router-link
    >

    <v-spacer></v-spacer>

    <UserAvatar
      :name="userName"
      color="primary"
      size="35"
      rounded="lg"
      class="cursor-pointer mr-2"
      tile
    >
      <v-menu
        activator="parent"
        location="bottom"
        transition="slide-y-transition"
        offset="15"
      >
        <ProfileDropdown />
      </v-menu>
    </UserAvatar>

    <v-btn
      :to="{ name: 'index' }"
      variant="tonal"
      size="35"
      rounded="lg"
      tile
      icon
    >
      <v-icon>close</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppNavStore } from "../stores/app-nav";

export default defineComponent({
  name: "SettingsHeader",
  setup() {
    const route = useRoute();
    const { toggle } = useAppNavStore();
    const { user } = useAuth();

    const userName = computed(() => user.value?.name);

    const isSettingsHome = computed(() => {
      return route.matched.some(({ name }) => name === "settings");
    });

    return {
      toggle,
      userName,
      isSettingsHome,
    };
  },
});
</script>
